import styles from "./Logo.module.css";
import wheelIcon from "../../data/wheel/wheel.svg";
import logoTextIcon from "../../data/wheel/Logo_Text_Milano.svg";

function Logo(props) {
  const onClickLogoHandler = () => {
    props.setWheelActive(!props.wheelActive);
  };
  return (
    <>
      <img
        className={styles.logo_img}
        src={wheelIcon}
        alt="SDG logo"
        onClick={onClickLogoHandler}
      />
      <img
        className={styles.textLogo_img}
        src={logoTextIcon}
        alt="Sustainable development goals"
        onClick={onClickLogoHandler}
      />
    </>
  );
}
export default Logo;
