import "leaflet/dist/leaflet.css";
import Map from "./Map";
import MapOZ from "./MapOZ";
import Chart from "./Chart";
import CustomTable from "./CustomTable";

function ContentBox(props) {
  const {
    chartData,
    arrowsData,
    mapDescription,
    chartReferenceValue,
    chartUnit,
    showMapOZ,
  } = props;

  function checkDataForMap(legendRange) {
    props.checkDataForMap(legendRange);
  }

  function cutEmptyData(valuesArray) {
    const emptyValueIndexes = [];
    valuesArray.forEach((oneYearValue, index) => {
      if (Number.isNaN(parseFloat(oneYearValue.refdata))) {
        emptyValueIndexes.push(index);
      }
    });
    emptyValueIndexes.reverse().forEach((id) => {
      valuesArray.splice(id, 1);
    });
  }
  return (
    <>
      {props.chartActive && (
        <Chart
          chartData={chartData}
          chartUnit={chartUnit}
          arrowsData={arrowsData}
          activeIndicator={props.activeIndicator}
          mapDescription={mapDescription}
          chartReferenceValue={chartReferenceValue}
        />
      )}
      {props.mapActive && (
        <Map
          mapDescription={mapDescription}
          cutEmptyData={cutEmptyData}
          currentTargets={props.currentTargets}
          chartData={chartData}
          chartUnit={chartUnit}
          activeIndicator={props.activeIndicator}
          checkDataForMap={checkDataForMap}
        />
      )}
      {props.mapOZActive && (
        <MapOZ
          mapDescription={mapDescription}
          cutEmptyData={cutEmptyData}
          currentTargets={props.currentTargets}
          chartData={chartData}
          chartUnit={chartUnit}
          activeIndicator={props.activeIndicator}
          checkDataForMap={checkDataForMap}
        />
      )}
      {props.tableActive && (
        <CustomTable
          chartData={chartData}
          chartUnit={chartUnit}
          mapDescription={mapDescription}
          showMapOZ={showMapOZ}
          width={props.width}
          activeIndicator={props.activeIndicator}
        />
      )}
    </>
  );
}

export default ContentBox;
