import export_csv from "../../data/DownloadIcons/ExportCSV.svg";
import export_xls from "../../data/DownloadIcons/ExportXLS.svg";
// import { FaFileDownload } from "react-icons/fa";
import styles from "./ContainerNavigation.module.css";

import ChartInfo from "./ChartInfo";

function ContainerNavigation(props) {
  const { mapActive, setMapActive } = props.mapState;
  const { chartActive, setChartActive } = props.chartState;
  const { tableActive, setTableActive } = props.tableState;
  const { mapOZActive, setMapOZActive } = props.mapOZState;
  const { showMap, showMapOZ, showChart } = props;

  const onClickHandler = (event) => {
    if (event.target.id === "chart") {
      setChartActive(true);
      setMapActive(false);
      setTableActive(false);
      setMapOZActive(false);
    }
    if (event.target.id === "map") {
      setChartActive(false);
      setMapActive(true);
      setTableActive(false);
      setMapOZActive(false);
    }
    if (event.target.id === "table") {
      setChartActive(false);
      setMapActive(false);
      setTableActive(true);
      setMapOZActive(false);
    }
    if (event.target.id === "mapOZ") {
      setChartActive(false);
      setMapActive(false);
      setTableActive(false);
      setMapOZActive(true);
    }
  };
  const chartItemClass = chartActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const mapItemClass = mapActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const mapOZItemClass = mapOZActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;
  const tableItemClass = tableActive
    ? `${styles.list_item} ${styles.list_item_active}`
    : `${styles.list_item} `;

  // we do not show the map for the indicator number 14, 17, 25
  const showProvinceMap =
    !(
      props.activeIndicator !== undefined &&
      props.activeIndicator !== null &&
      (Number(props.activeGoal.id) === 14 ||
        Number(props.activeGoal.id) === 17 ||
        Number(props.activeIndicator.data.id) === 25)
    ) && showMap;

    let exportXlsUrl;
    let exportCsvUrl;
  
    if (props.activeIndicator !== null) {
      exportXlsUrl = `https://trf3.colouree.com/export/xls/${props.activeIndicator.data.id}`;
      exportCsvUrl = `https://trf3.colouree.com/export/csv/${props.activeIndicator.data.id}`;
    } 
  
  return (
    <div className={styles.nav_bar} id="container_navigation">
      <ul className={styles.nav_list}>
        {showChart && (
          <li className={chartItemClass} onClick={onClickHandler} id="chart">
            Diagramma
          </li>
        )}
        {showProvinceMap && (
          <li className={mapItemClass} onClick={onClickHandler} id="map">
            Mappa
          </li>
        )}
        {showMapOZ && (
          <li className={mapOZItemClass} onClick={onClickHandler} id="mapOZ">
            Mappa Z.O.
          </li>
        )}
        <li className={tableItemClass} onClick={onClickHandler} id="table">
          Tabella
        </li>
        <ChartInfo
          mapDescription={props.mapDescription}
          tableActive={tableActive}
          mapActive={mapActive}
          mapDataExists={props.mapDataExists}
        />
        {tableActive && (
          <div id="exportTableIcon" className={styles.download_icons_wrapper}>
            <a href={exportXlsUrl} download target="_blank" rel="noreferrer">
              <img
                src={export_xls}
                alt="Scarica i dati in formato excel - xls"
                className={styles.download_icon}
              />
            </a>
            <a href={exportCsvUrl} download target="_blank" rel="noreferrer">
              <img
                src={export_csv}
                alt="Scarica i dati in formato excel - csv"
                className={styles.download_icon}
              />
            </a>
          </div>
        )}
      </ul>
    </div>
  );
}
export default ContainerNavigation;
