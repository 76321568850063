import { useEffect, useRef } from "react";
import styles from "./LegendSidebar.module.css";
import Sidebar from "../../UI/Sidebar";
import configData from "../../config.json";

import goal_global from "../../data/Goals/global_goals.png";

function LegendSidebar(props) {
  const sidebarRef = useRef();

  // when we open a dashboard we will look for the active goal and we will add a class first_goal_active
  // to active color, then it will be removed when we click on sidebarLegend element
  useEffect(() => {
    const goalActive = document.body.querySelector(
      `.goalIcon_${props.activeGoal.id}`
    );
    goalActive.classList.add(`first_goal_active`);
  }, []);

  const onClickHandler = (event) => {
    props.setActiveGoal((prevState) => ({
      ...prevState,
      goalIcon: event.target.src,
      id: event.target.id,
      nodeElement: event.target,
    }));
    if (props.isMobileScreen) {
      props.setMobileLegendActive(!props.mobileLegendActive);
    }

    // delete  the goal which was activated after clicking the wheel
    const wheelActivatedGoal =
      document.body.querySelector(".first_goal_active");
    if (wheelActivatedGoal !== null) {
      wheelActivatedGoal.classList.remove("first_goal_active");
    }
    /// ///////////////////////////////////////////////////////////////

    event.target.classList.add(`${styles.indicator_active}`);
    if (
      props.activeGoal.nodeElement != null &&
      props.activeGoal.id !== event.target.id
    ) {
      props.activeGoal.nodeElement.classList.remove(
        `${styles.indicator_active}`
      );
    }
  };

  function onClickGlobalGoalIconHandler() {
    window.open(configData.EXTERNAL_URL.COMUNI_MAP, "_blank");
  }

  const goals = props.goals.map((item) => {
    return (
      <img
        alt={item.goalTitle}
        className={`${styles.indicator} goalIcon_${item.id}`}
        src={item.goalIcon}
        onClick={onClickHandler}
        tabIndex={0}
        id={item.id}
        key={item.id}
        title={item.goalTitle}
      />
    );
  });
  return (
    <Sidebar>
      <div className={styles.container} ref={sidebarRef}>
        {goals}

        <img
          alt="The Global Goals"
          src={goal_global}
          tabIndex={0}
          id="The Global Goals"
          className={`${styles.indicator} ${styles.global_goals_icon}`}
          onClick={onClickGlobalGoalIconHandler}
          title="The Global Goals"
        />
      </div>
    </Sidebar>
  );
}
export default LegendSidebar;
