import React, { useState, useEffect } from "react";

import styles from "./IndicatorText.module.css";

function IndicatorText(props) {
  const [color, setColor] = useState(null);

  function onClickHandler(event) {
    props.indicators.forEach((indicator) => {
      if (indicator.id === Number(event.target.id)) {
        props.currentTargets.forEach((target) => {
          if (target.id === Number(indicator.targetid)) {
            props.setActiveTarget(target);
          }
        });
        props.setActiveIndicator((prevState) => ({
          ...prevState,
          data: props.indicator,
          nodeElement: event.target,
        }));
      }
    });
  }
  function onMouseOverHandler(event) {
    event.target.classList.add(
      `${styles[`goal_${props.activeTarget.goalid}`]}`
    );
  }
  function onMouseOutHandler(event) {
    event.target.classList.remove(
      `${styles[`goal_${props.activeTarget.goalid}`]}`
    );
  }

  useEffect(() => {
    const activeIndicatorId = props.activeIndicator.nodeElement
      ? props.activeIndicator.nodeElement.id
      : props.activeIndicator.data.id;
    if (props.indicator.id === Number(activeIndicatorId)) {
      setColor(props.activeTarget.color);
    } else {
      setColor(null);
    }
  }, [props.indicator, props.activeIndicator]);

  const indicatorStyle =
    color !== null
      ? `${styles.description}  ${
          styles[`goal_${props.activeTarget.goalid}_active`]
        }`
      : `${styles.description}`;
  return (
    <p
      className={indicatorStyle}
      onMouseOver={onMouseOverHandler}
      onFocus={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
      onBlur={onMouseOutHandler}
      onClick={onClickHandler}
      id={props.indicator.id}
      data-indicatorid={props.indicator.id}
      targetid={props.targetId}
    >
      {props.indicator.description}
    </p>
  );
}

export default IndicatorText;
