import { useState } from "react";
import styles from "./GoalsDefinition.module.css";
import manuale from "../data/wheel/DataLab Milano - Manuale v1_1.pdf";
import { FaFileDownload } from "react-icons/fa";

// import of goals icons
import goal_1 from "../data/Goals/Goal_01.svg";
import goal_2 from "../data/Goals/Goal_02.svg";
import goal_3 from "../data/Goals/Goal_03.svg";
import goal_4 from "../data/Goals/Goal_04.svg";
import goal_5 from "../data/Goals/Goal_05.svg";
import goal_6 from "../data/Goals/Goal_06.svg";
import goal_7 from "../data/Goals/Goal_07.svg";
import goal_8 from "../data/Goals/Goal_08.svg";
import goal_9 from "../data/Goals/Goal_09.svg";
import goal_10 from "../data/Goals/Goal_10.svg";
import goal_11 from "../data/Goals/Goal_11.svg";
import goal_12 from "../data/Goals/Goal_12.svg";
import goal_13 from "../data/Goals/Goal_13.svg";
import goal_14 from "../data/Goals/Goal_14.svg";
import goal_15 from "../data/Goals/Goal_15.svg";
import goal_16 from "../data/Goals/Goal_16.svg";
import goal_17 from "../data/Goals/Goal_17.svg";

import arrowsExplanationsImg from "../data/GoalDefinitions/FrecceSpiegazioneCharts.svg";
import Footer from "../UI/Footer";

function GoalsDefinition() {
  const [openGoal_1, setOpenGoal_1] = useState(false);
  const [openGoal_2, setOpenGoal_2] = useState(false);
  const [openGoal_3, setOpenGoal_3] = useState(false);
  const [openGoal_4, setOpenGoal_4] = useState(false);
  const [openGoal_5, setOpenGoal_5] = useState(false);
  const [openGoal_6, setOpenGoal_6] = useState(false);
  const [openGoal_7, setOpenGoal_7] = useState(false);
  const [openGoal_8, setOpenGoal_8] = useState(false);
  const [openGoal_9, setOpenGoal_9] = useState(false);
  const [openGoal_10, setOpenGoal_10] = useState(false);
  const [openGoal_11, setOpenGoal_11] = useState(false);
  const [openGoal_12, setOpenGoal_12] = useState(false);
  const [openGoal_13, setOpenGoal_13] = useState(false);
  const [openGoal_14, setOpenGoal_14] = useState(false);
  const [openGoal_15, setOpenGoal_15] = useState(false);
  const [openGoal_16, setOpenGoal_16] = useState(false);
  const [openGoal_17, setOpenGoal_17] = useState(false);
  function onClickHandlerGoal1() {
    setOpenGoal_1(!openGoal_1);
  }
  function onClickHandlerGoal2() {
    setOpenGoal_2(!openGoal_2);
  }
  function onClickHandlerGoal3() {
    setOpenGoal_3(!openGoal_3);
  }
  function onClickHandlerGoal4() {
    setOpenGoal_4(!openGoal_4);
  }
  function onClickHandlerGoal5() {
    setOpenGoal_5(!openGoal_5);
  }
  function onClickHandlerGoal6() {
    setOpenGoal_6(!openGoal_6);
  }
  function onClickHandlerGoal7() {
    setOpenGoal_7(!openGoal_7);
  }
  function onClickHandlerGoal8() {
    setOpenGoal_8(!openGoal_8);
  }
  function onClickHandlerGoal9() {
    setOpenGoal_9(!openGoal_9);
  }
  function onClickHandlerGoal10() {
    setOpenGoal_10(!openGoal_10);
  }
  function onClickHandlerGoal11() {
    setOpenGoal_11(!openGoal_11);
  }
  function onClickHandlerGoal12() {
    setOpenGoal_12(!openGoal_12);
  }
  function onClickHandlerGoal13() {
    setOpenGoal_13(!openGoal_13);
  }
  function onClickHandlerGoal14() {
    setOpenGoal_14(!openGoal_14);
  }
  function onClickHandlerGoal15() {
    setOpenGoal_15(!openGoal_15);
  }
  function onClickHandlerGoal16() {
    setOpenGoal_16(!openGoal_16);
  }
  function onClickHandlerGoal17() {
    setOpenGoal_17(!openGoal_17);
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <h3 className={styles.introTitle}>SDG e Agenda 2030</h3>
          <p className={styles.introText}>
            L’Agenda 2030 per lo Sviluppo Sostenibile è un programma d’azione
            per le persone, il pianeta e la prosperità sottoscritto nel
            settembre 2015 dai governi dei 193 Paesi membri dell’ONU, e ingloba
            17 Obiettivi per lo Sviluppo Sostenibile (SDGs, Sustainable
            Development Goals), che rappresentano obiettivi comuni su un insieme
            di questioni importanti per lo sviluppo.
          </p>
        </div>
        <div className={styles.goalsSection}>
          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_1}
                className={styles.goalIcon}
                alt="1 - Sconfiggere la povertà"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal1}>
                  1 - Sconfiggere la povertà
                </p>
                {openGoal_1 && (
                  <p className={styles.goalText}>
                    Nel mondo quasi un miliardo di persone vive ancora in
                    condizioni di estrema povertà. L’Agenda per lo sviluppo
                    sostenibile si pone l’obiettivo di eliminare la povertà
                    estrema entro il 2030. Oltre l’obiettivo di sradicare la
                    povertà estrema, l’Obiettivo 1 riguarda un approccio globale
                    alla povertà nel suo complesso, ma pone anche un bersaglio
                    sulle singole realtà nazionali di povertà. I poveri sono
                    particolarmente vulnerabili alle crisi economiche e
                    politiche, alle problematiche di biodiversità e degli
                    ecosistemi, delle catastrofi naturali e della violenza. Sono
                    previste anche misure per rafforzare la resilienza al
                    sostentamento e i sistemi di sicurezza sociale.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_2}
                className={styles.goalIcon}
                alt="2 - Sconfiggere la fame"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal2}>
                  2 - Sconfiggere la fame
                </p>
                {openGoal_2 && (
                  <p className={styles.goalText}>
                    Molte persone soffrono ancora la fame e la malnutrizione in
                    tutto il mondo: la denutrizione colpisce quasi 800 milioni
                    di persone in tutto il mondo, la maggior parte dei quali
                    donne e bambini. L’obiettivo del 2030 dell’ Agenda è quello
                    di porre fine alla fame e tutte le forme di malnutrizione in
                    tutto il mondo entro il 2030. L’Obiettivo 2 mira anche agli
                    aspetti economici, come raddoppiare la produttività agricola
                    e il reddito dei piccoli agricoltori, e contiene anche
                    disposizioni in materia di agricoltura sostenibile per
                    prevenire un aumento della produzione di cibo che possa
                    danneggiare l’ambiente.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_3}
                className={styles.goalIcon}
                alt="3 - Salute e benessere"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal3}>
                  3 - Salute e benessere
                </p>
                {openGoal_3 && (
                  <p className={styles.goalText}>
                    Tutte le persone dovrebbero avere accesso ai servizi
                    sanitari e ai farmaci di buona qualità: l’Obiettivo 3
                    continua lungo le linee dettate dagli Obiettivi di sviluppo
                    del millennio (MDG), che hanno dato un contributo
                    significativo al miglioramento della salute globale (ad
                    esempio nella lotta all’AIDS, alla tubercolosi e alla
                    malaria) Un altro obiettivo per il 2030 è quello di
                    garantire l’accesso universale alla salute sessuale e
                    riproduttiva, compresa la pianificazione familiare,
                    l’informazione e l’educazione.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_4}
                className={styles.goalIcon}
                alt="4 - Istruzione di qualità"
              />

              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal4}>
                  4 - Istruzione di qualità
                </p>
                {openGoal_4 && (
                  <p className={styles.goalText}>
                    La comunità internazionale ha ribadito l’importanza della
                    formazione e istruzione di buona qualità per il
                    miglioramento delle condizioni di vita delle persone, delle
                    comunità e intere società. L’Obiettivo 4 evidenzia in
                    particolare il legame tra istruzione di base e la formazione
                    professionale, oltre a sottolineare l’equità e la qualità
                    dell’istruzione in un approccio di apprendimento per tutta
                    la vita. Tutti i bambini, gli adolescenti e gli adulti, in
                    particolare se emarginati e vulnerabili, devono avere
                    accesso ad istruzione e formazione adeguate alle loro
                    esigenze e al contesto in cui vivono.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_5}
                className={styles.goalIcon}
                alt="5 - Parità di genere"
              />

              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal5}>
                  5 - Parità di genere
                </p>
                {openGoal_5 && (
                  <p className={styles.goalText}>
                    La disuguaglianza di genere è uno dei maggiori ostacoli allo
                    sviluppo sostenibile, alla crescita economica e alla
                    riduzione della povertà. L’Obiettivo 5 sostiene le pari
                    opportunità tra uomini e donne nella vita economica,
                    l’eliminazione di tutte le forme di violenza contro le donne
                    e le ragazze, l’eliminazione dei matrimoni precoci e
                    forzati, e la parità di partecipazione a tutti i livelli.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_6}
                className={styles.goalIcon}
                alt="6 - Acqua pulita e servizi igienico-sanitari"
              />

              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal6}>
                  6 - Acqua pulita e servizi igienico-sanitari
                </p>
                {openGoal_6 && (
                  <p className={styles.goalText}>
                    L’accesso all’acqua potabile e ai servizi igienici è un
                    diritto umano e un fattore determinante in tutti gli aspetti
                    dello sviluppo sociale, economico e ambientale. Oltre
                    all’accesso all’acqua potabile e ai servizi igienici,
                    L’Obiettivo 6 affronta temi come la protezione e il
                    ripristino degli ecosistemi legati all’acqua, mirando quindi
                    a migliorare la qualità dell’acqua e ridurne le forme di
                    inquinamento, in particolare quello da sostanze chimiche
                    pericolose.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_7}
                className={styles.goalIcon}
                alt="7 - Energia pulita e accessibile"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal7}>
                  7 - Energia pulita e accessibile
                </p>
                {openGoal_7 && (
                  <p className={styles.goalText}>
                    L’accesso all’energia è un prerequisito essenziale per
                    l’eliminazione della povertà, l’aumento della produzione
                    alimentare, la fornitura di acqua pulita, il miglioramento
                    della sanità pubblica e dell’istruzione, la creazione di
                    opportunità economiche e l’emancipazione delle donne.
                    Attualmente, ben 1,6 miliardi di persone in tutto il mondo
                    non hanno accesso all’energia elettrica. L’Obiettivo 7
                    sostiene in tal modo l’accesso universale e affidabile ai
                    servizi di produzione di energia moderni a prezzi
                    accessibili, con particolare attenzione alle energie
                    rinnovabili e all’efficienza energetica.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_8}
                className={styles.goalIcon}
                alt="8 - Lavoro dignitoso e crescita economica"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal8}>
                  8 - Lavoro dignitoso e crescita economica
                </p>
                {openGoal_8 && (
                  <p className={styles.goalText}>
                    Oggi, nel mondo, oltre 200 milioni di persone sono
                    disoccupati. La promozione di una crescita sostenibile e la
                    creazione di sufficienti posti di lavoro dignitoso e
                    rispettoso dei diritti umani sono di fondamentale importanza
                    non solo per i paesi in via di sviluppo ma anche per le
                    economie emergenti e quelle industrializzate. L’Obiettivo 8
                    comprende obiettivi sul sostegno della crescita economica,
                    aumentando la produttività economica e la creazione di posti
                    di lavoro dignitosi, prevedendo anche la lotta contro il
                    lavoro forzato e la fine della schiavitù moderna e traffico
                    di esseri umani entro il 2030; mira, altresì, a una migliore
                    efficienza dei consumi delle risorse globali e della
                    produzione, prevenendo un degrado ambientale legato alla
                    crescita economica.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_9}
                className={styles.goalIcon}
                alt="9 - Imprese, innovazione e infrastrutture"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal9}>
                  9 - Imprese, innovazione e infrastrutture
                </p>
                {openGoal_9 && (
                  <p className={styles.goalText}>
                    Gli investimenti in infrastrutture sostenibili e nella
                    ricerca scientifica e tecnologica aumentano la crescita
                    economica, creano posti di lavoro e promuovono la
                    prosperità. L’Obiettivo 9 mira a costruire infrastrutture
                    resistenti, promuovere l’industrializzazione e promuovere
                    l’innovazione, oltre a fornire a piccole industrie e aziende
                    un maggiore accesso ai servizi finanziari e di credito a
                    prezzi accessibili, così come aumentare l’integrazione di
                    queste aziende nei mercati.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_10}
                className={styles.goalIcon}
                alt="10 - Ridurre le disuguaglianze"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal10}>
                  10 - Ridurre le disuguaglianze
                </p>
                {openGoal_10 && (
                  <p className={styles.goalText}>
                    Le disuguaglianze rappresentano uno dei maggiori ostacoli
                    allo sviluppo sostenibile e alla lotta contro la povertà,
                    poiché limitano le opportunità di partecipare alla vita dei
                    gruppi sociali e di dare un contributo significativo alla
                    vita sociale, culturale, politica ed economica. L’Obiettivo
                    10 mira alla crescita del reddito delle classi più povere,
                    promuovendo pari opportunità attraverso l’eliminazione delle
                    leggi discriminatorie, facilitando una più regolarizzata e
                    sicura migrazione umana attraverso l’attuazione di adeguate
                    politiche di migrazione. L’Obiettivo 10 prevede anche una
                    maggiore rappresentanza e una maggiore voce dei paesi in via
                    di sviluppo nel processo decisionale all’interno delle
                    istituzioni economiche e finanziarie internazionali.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_11}
                className={styles.goalIcon}
                alt="11 - Città e comunità sostenibili"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal11}>
                  11 - Città e comunità sostenibili
                </p>
                {openGoal_11 && (
                  <p className={styles.goalText}>
                    Le città, che attualmente accolgono più del 50% della
                    popolazione mondiale, guidano le economie locali e
                    nazionali, come centri di prosperità dove si concentra oltre
                    l’80% delle attività economiche globali. Le città, tuttavia,
                    hanno un enorme impatto ambientale, in quanto occupano solo
                    il 3% della superficie del mondo ma sono responsabili per il
                    75% dei consumi delle risorse e delle emissioni globali.
                    L’Obiettivo 11 mira a ridurre gli effetti negativi
                    dell’impatto ambientale delle città, in particolare in
                    termini di qualità dell’aria e gestione dei rifiuti, e mira
                    anche a garantire l’accesso universale a spazi verdi e
                    pubblici sicuri e inclusivi, soprattutto per le donne ei
                    bambini, gli anziani e le persone con disabilità, così come
                    a fornire l’accesso ai sistemi di trasporto sicuri e
                    convenienti.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_12}
                className={styles.goalIcon}
                alt="12 - Consumo e produzione responsabili"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal12}>
                  12 - Consumo e produzione responsabili
                </p>
                {openGoal_12 && (
                  <p className={styles.goalText}>
                    La popolazione mondiale attualmente consuma più risorse
                    rispetto a quelle che gli ecosistemi siano in grado di
                    fornire. L’Obiettivo 12 mira alla gestione ecologica dei
                    prodotti chimici e di tutti i rifiuti, nonché a una
                    sostanziale riduzione della produzione di rifiuti attraverso
                    processi virtuosi quali il riciclaggio. L’Obiettivo 12 ha
                    anche lo scopo di dimezzare lo spreco alimentare,
                    incoraggiare le imprese ad adottare pratiche sostenibili e
                    promuovere politiche in materia di appalti pubblici
                    sostenibili.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_13}
                className={styles.goalIcon}
                alt="13 - Lotta contro il cambiamento climatico"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal13}>
                  13 - Lotta contro il cambiamento climatico
                </p>
                {openGoal_13 && (
                  <p className={styles.goalText}>
                    Il riscaldamento del clima terrestre sta provocando
                    cambiamenti nel sistema climatico globale che minacciano la
                    sopravvivenza di ampie fasce di popolazione nei paesi meno
                    sviluppati, mentre le infrastrutture e alcuni settori
                    economici sono vulnerabili ai rischi dei cambiamenti
                    climatici; questi cambiamenti che interessano precipitazioni
                    e temperatura stanno colpendo fortemente ecosistemi come
                    foreste, terreni agricoli, regioni di montagna e oceani,
                    così come le piante, gli animali e le persone che vi
                    abitano. Parallelamente stiamo assistendo anche a un
                    vertiginoso aumento dell’anidride carbonica globale (CO2).
                    L’Obiettivo 13 invita i paesi a dotarsi di misure di
                    protezione del clima nelle loro politiche nazionali e a
                    prestarsi reciproca assistenza per rispondere alle sfide
                    quando necessario. L’Obiettivo 13 è favorevole al
                    rafforzamento della resilienza alle calamità naturali legate
                    al clima e riafferma l’impegno assunto dai paesi sviluppati
                    a mobilitare ogni anno 100 miliardi di dollari
                    congiuntamente da tutte le fonti, entro il 2020, per aiutare
                    i paesi in via di sviluppo ad adattarsi ai cambiamenti
                    climatici.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_14}
                className={styles.goalIcon}
                alt="14 - Vita sott'acqua"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal14}>
                  14 - Vita sott’acqua
                </p>
                {openGoal_14 && (
                  <p className={styles.goalText}>
                    Inquinamento e sfruttamento eccessivo degli oceani sono la
                    causa di problemi per la biodiversità, ad esempio a causa
                    dell’acidificazione degli oceani e dell’aumento dei rifiuti
                    di plastica. L’Obiettivo 14 mira a ridurre in modo
                    significativo tutti i tipi di inquinamento marino, riducendo
                    al minimo l’acidificazione degli oceani entro il 2025,
                    affrontando in modo sostenibile la gestione e la protezione
                    degli ecosistemi marini e costieri, e mira a regolamentare
                    la raccolta in modo efficace e a bloccare la pesca
                    eccessiva, ponendo fine alla pesca illegale e non
                    regolamentata e le pratiche di pesca distruttive.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_15}
                className={styles.goalIcon}
                alt="15 - Vita sulla terra"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal15}>
                  15 - Vita sulla terra
                </p>
                {openGoal_15 && (
                  <p className={styles.goalText}>
                    Pur essendo la biodiversità di vitale importanza per lo
                    sviluppo sociale ed economico, nonché per la sopravvivenza
                    dell’umanità, stiamo assistendo a un suo continuo declino,
                    con perdita della superficie forestale che minaccia la
                    prosperità umana e impoverimento delle popolazioni rurali
                    povere. Biodiversità e foreste assicurano aria pulita e
                    acqua, assorbendo le emissioni di CO2 oltreché lo sviluppo
                    ambientale. L’Obiettivo 15 è finalizzato alla conservazione,
                    restauro e uso sostenibile degli ecosistemi, con l’obiettivo
                    di fermare la deforestazione, assicurare il ripristino delle
                    foreste degradate e sostanzialmente aumentare il
                    rimboschimento entro il 2020; partecipa, inoltre, alla lotta
                    alla desertificazione e al ripristino dei terreni
                    interessati da siccità e inondazioni.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_16}
                className={styles.goalIcon}
                alt="16 - Pace, giustizia e istituzioni forti"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal16}>
                  16 - Pace, giustizia e istituzioni forti
                </p>
                {openGoal_16 && (
                  <p className={styles.goalText}>
                    Lo sviluppo non può essere sostenuto senza una comunità
                    pacifica e inclusiva e una governance efficace. L’Obiettivo
                    16 mira pertanto a promuovere società pacifiche e inclusive
                    e sostiene di ridurre ogni forma di violenza, comprese la
                    tortura e la lotta contro la criminalità organizzata;
                    prevede anche di ridurre in modo significativo corruzione e
                    concussione, così come flussi finanziari illeciti e di armi,
                    e promuovere istituzioni inclusive, stato di diritto, e una
                    parità di accesso alla giustizia.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goalsContainer}>
            <div className={styles.goalBox}>
              <img
                src={goal_17}
                className={styles.goalIcon}
                alt="17 - Partnership per gli obiettivi"
              />
              <div className={styles.goalContent}>
                <p className={styles.goalTitle} onClick={onClickHandlerGoal17}>
                  17 - Partnership per gli obiettivi
                </p>
                {openGoal_17 && (
                  <p className={styles.goalText}>
                    L’Obiettivo 17 invita i paesi sviluppati a rinnovare il loro
                    impegno di destinare lo 0,7% del reddito lordo nazionale
                    all’aiuto pubblico allo sviluppo; ha lo scopo di garantire
                    una maggiore mobilitazione delle risorse interne per ridurre
                    la dipendenza dal sostegno straniero, così come una maggiore
                    collaborazione internazionale nel campo delle scienze,
                    tecnologia e innovazione, e la promozione di un sistema
                    commerciale multilaterale equo. L’Obiettivo 17 sostiene
                    anche il miglioramento della stabilità macroeconomica e la
                    coerenza delle politiche nell’interesse di uno sviluppo
                    sostenibile.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.additionalInfo}>
          <h4 className={styles.additionalInfoTitle}>Nota metodologica</h4>
          <div className={styles.additionalInfoTextContainer}>
            <p className={styles.additionalInfoText}>
              Le schede all’interno della dashboard interattiva presentano (in
              forma di diagramma, mappa territoriale o tabella numerica) i dati
              relativi agli indicatori scelti per monitorare i progressi di
              Città Metropolitana di Milano relativamente ai 17 Obiettivi di
              Sviluppo Sostenibile.
            </p>
            <p className={styles.additionalInfoText}>
              Le fonti per gli indicatori sono state selezionate in base alla
              loro affidabilità e autorevolezza, così come secondo granulometria
              e frequenza di aggiornamento dei dati; quando possibile, le schede
              mostrano per ogni indicatore un confronto tra i dati di Città
              Metropolitana di Milano e quelli di altre realtà territoriali (ad
              esempio con i dati delle altre Province di Regione Lombardia sulla
              mappa, oppure con i dati nazionali sul diagramma e sulla tabella).
              La visualizzazione su mappa consente anche un confronto
              cronologico tra i dati relativi al territorio regionale, provincia
              per provincia, anno per anno, quando disponibili serie congrue di
              dati.
            </p>
            <p className={styles.additionalInfoText}>
              All’interno della scheda &apos;Diagramma&apos; è riportata, quando
              possibile sulla base dei dati a disposizione, la tabella
              &apos;Andamento&apos;: con una simbologia che segue la Metodologia
              EUROSTAT, le frecce mostrano con la loro direzione e il colore
              l’andamento positivo o negativo dell’indicatore. Se l’indicatore
              mira a un Obiettivo Quantitativo, già istituzionalizzato e
              stabilito numericamente (v. note all’interno della scheda Tabella
              per l’individuazione dell’eventuale Obiettivo Quantitativo per
              quell’indicatore), la freccia mostra l’andamento in relazione a
              tale obiettivo; in assenza di un Obiettivo Quantitativo, invece,
              la frecca indica l’andamento dei valori sulla base di un obiettivo
              &apos;standard&apos;(es.: diminuire progressivamente il valore di
              riferimento). Breve e lungo periodo si riferiscono al periodo di
              riferimento per l’analisi dell’andamento (ultimi 5 o 15 anni).
            </p>
          </div>
          <img
            src={arrowsExplanationsImg}
            className={styles.arrowsLegendIcon}
            alt="freccie spegazione"
          />
          <a
            // onClick={onClickHandler}
            className={styles.downloadBtn}
            href={manuale}
            download
          >
            <span className={styles.downloadContent}><FaFileDownload className={styles.downloadIcon} /> <p>Manuale d’uso</p></span>

          </a>
        </div>
      </div>
      <Footer />
    </div >
  );
}
export default GoalsDefinition;
