import { useState } from "react";
import styles from "./DevelopmentTrend.module.css";
import Footer from "../UI/Footer";

// import button1 from "../data/trend_di_sviluppo/button1.svg";
// import button2 from "../data/trend_di_sviluppo/button2.svg";
// import button3 from "../data/trend_di_sviluppo/button3.svg";

import button1 from "../data/trend_di_sviluppo/buttons/button1.svg";
import button2 from "../data/trend_di_sviluppo/buttons/button2.svg";
import button3 from "../data/trend_di_sviluppo/buttons/button3.svg";
import button4 from "../data/trend_di_sviluppo/buttons/button4.svg";
import button5 from "../data/trend_di_sviluppo/buttons/button5.svg";
import button6 from "../data/trend_di_sviluppo/buttons/button6.svg";
import button7 from "../data/trend_di_sviluppo/buttons/button7.svg";


// import trend1 from "../data/trend_di_sviluppo/Milano/Trend1.svg";
// import trend2 from "../data/trend_di_sviluppo/Milano/Trend2.svg";
// import trend3 from "../data/trend_di_sviluppo/Milano/Trend3.svg";

import goal1_icon from "../data/Goals/Goal_01.svg";
import goal2_icon from "../data/Goals/Goal_02.svg";
import goal3_icon from "../data/Goals/Goal_03.svg";
import goal4_icon from "../data/Goals/Goal_04.svg";
import goal5_icon from "../data/Goals/Goal_05.svg";
import goal6_icon from "../data/Goals/Goal_06.svg";
import goal7_icon from "../data/Goals/Goal_07.svg";
import goal8_icon from "../data/Goals/Goal_08.svg";
import goal9_icon from "../data/Goals/Goal_09.svg";
import goal10_icon from "../data/Goals/Goal_10.svg";
import goal11_icon from "../data/Goals/Goal_11.svg";
import goal12_icon from "../data/Goals/Goal_12.svg";
import goal13_icon from "../data/Goals/Goal_13.svg";
import goal14_icon from "../data/Goals/Goal_14.svg";
import goal15_icon from "../data/Goals/Goal_15.svg";
import goal16_icon from "../data/Goals/Goal_16.svg";
import goal17_icon from "../data/Goals/Goal_17.svg";

import tabel1 from "../data/trend_di_sviluppo/tabels/tabel1.svg";
import tabel2 from "../data/trend_di_sviluppo/tabels/tabel2.svg";
import tabel3 from "../data/trend_di_sviluppo/tabels/tabel3.svg";
import tabel4 from "../data/trend_di_sviluppo/tabels/tabel4.svg";
import tabel5 from "../data/trend_di_sviluppo/tabels/tabel5.svg";
import tabel6 from "../data/trend_di_sviluppo/tabels/tabel6.svg";
import tabel7 from "../data/trend_di_sviluppo/tabels/tabel7.svg";

import arrowsExplanationsImg from "../data/GoalDefinitions/FrecceSpiegazioneNoCharts.svg";

function DevelopmentTrend(props) {
  const [button1Active, setButton1Active] = useState(true);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);

  const [activeButtons,setActiveButtons] = useState({
    button_1:true,
    button_2:false,
    button_3:false,
    button_4:false,
    button_5:false,
    button_6:false,
    button_7:false
  });

  function onClickHandler(event) {
    const idBtn = event.target.id;

    let updatedButtons = {
        button_1:false,
        button_2:false,
        button_3:false,
        button_4:false,
        button_5:false,
        button_6:false,
        button_7:false
    };
    updatedButtons[`button_${idBtn}`] = true;
    setActiveButtons(updatedButtons);
  };

  const activeBtn1Style = activeButtons.button_1
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn2Style = activeButtons.button_2
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn3Style = activeButtons.button_3
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
    const activeBtn4Style = activeButtons.button_4
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn5Style = activeButtons.button_5
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn6Style = activeButtons.button_6
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;
  const activeBtn7Style = activeButtons.button_7
    ? `${styles.btnImage} ${styles.btnImage_active}`
    : `${styles.btnImage}`;

  const secondTrendSubtitle = `${styles.trendSubtitle} ${styles.trendIndicatorsSubtitle}`;

  const currentURL = window.location.origin;

  return (
    <div className={styles.main}>
      <div
        className={styles.introWrapper}
        style={{
          backgroundImage: `url(${currentURL}/img/introImg.jpg)`,
        }}
      >
        <div className={styles.introTitleBox} />
        <h3 className={styles.introTitle}>Traiettorie</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.intro}>
          <p className={styles.introText}>
          Le <b>Traiettorie</b> sono la visione concreta, fondata sugli Obiettivi dell’Agenda 2030, verso le quali Città Metropolitana di Milano sta convogliando volontà, progettualità, azioni e investimenti.
          Lo sviluppo di ogni Traiettoria fa riferimento ad una selezione trasversale di più indicatori tratti da diversi Obiettivi di Sviluppo Sostenibile, costantemente sottoposti ad una lettura critica secondo alcuni princìpi fondanti, denominati <b>Valori</b> e selezionati sulla base degli SDG 4, 5, 10 e 17.
          In questa pagina vengono presentate le 6 Traiettorie individuate da Città Metropolitana di Milano, nonché la selezione di Valori che ne permettono una lettura più critica e consapevole.

          </p>
        </div>
        <div className={styles.trendsWrapper}>
          <div className={styles.buttonsContainer}>
            <img
              src={button1}
              className={activeBtn1Style}
              alt="Traiettoria Energetica"
              onClick={onClickHandler}
              id='1'
            />
            <img
              src={button2}
              className={activeBtn2Style}
              alt="Traiettoria Economia Circolare"
              onClick={onClickHandler}
              id='2'
            />
            <img
              src={button3}
              className={activeBtn3Style}
              alt="Traiettoria Resiliente"
              onClick={onClickHandler}
              id='3'
            />
            <img
              src={button4}
              className={activeBtn4Style}
              alt="Traiettoria Ecologica"
              onClick={onClickHandler}
              id='4'
            />
            <img
              src={button5}
              className={activeBtn5Style}
              alt="Traiettoria Digitale"
              onClick={onClickHandler}
              id='5'
            />
            <img
              src={button6}
              className={activeBtn6Style}
              alt="Traiettoria Crescita Economica"
              onClick={onClickHandler}
              id='6'
            />
            <img
              src={button7}
              className={activeBtn7Style}
              alt="Valori"
              onClick={onClickHandler}
              id='7'
            />
          </div>

          {activeButtons.button_1 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria energetica</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                Nel comune sforzo di raddoppiare il tasso globale di efficienza energetica, Città Metropolitana di Milano vuole favorire e rendere accessibile a tutti gli strumenti tecnologici e di conoscenza del suo territorio per incrementare la quota di energie rinnovabili e rendere più efficienti gli edifici esistenti, ponendosi al centro di un sistema di governance territoriale volta al supporto degli Enti. La Traiettoria comprende azioni per recuperare efficienza energetica, tramite azioni concrete e misurabili per ridurre i consumi energetici, contenere le emissioni di CO2 nell’atmosfera, ridurre i costi della pubblica amministrazione, per aumentare la qualità di vita dei cittadini, verso l’obiettivo carbon zero.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal7_icon} className={styles.goalImage} alt="Energia pulita e accessibile" />
                <img src={goal8_icon} className={styles.goalImage} alt="Lavoro dignitoso e crescita economica" />
                <img src={goal13_icon} className={styles.goalImage} alt="Lotta contro il cambiamento climatico" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel1} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_2 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria economia circolare</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                Città Metropolitana di Milano è impegnata nella promozione e nel supporto di azioni di economia circolare per garantire uno sviluppo sostenibile del suo territorio che tenga conto della crescita economica, sociale e ambientale. L’obiettivo è supportare un nuovo paradigma dell’economia per incentivare la circolarità della materia, riducendo il consumo di materie prime e di conseguenza la produzione di rifiuti.
                L’Ente, inoltre, attua partnership costruttive fra realtà interessate della pubblica amministrazione, imprese private e il mondo della ricerca, per favorire nuove opportunità di economia circolare, in una visione di sostenibilità ambientale, finanza etica e innovazione dei processi produttivi e dei servizi e per assumere un ruolo centrale e propositivo nel processo di aggiornamento e adeguamento della legislazione nazionale in materia di end-of-waste.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal7_icon} className={styles.goalImage} alt="Energia pulita e accessibile" />
                <img src={goal12_icon} className={styles.goalImage} alt="Consumo e produzione responsabili" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel2} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_3 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria resiliente</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                Promuovere ed attuare misure di adattamento ai cambiamenti climatici e sviluppare contestualmente politiche di mitigazione al fine di avere un territorio più resiliente in cui ci sia una qualità elevata del lavorare e del vivere. Il fine è quello di rendere il territorio metropolitano capace di assorbire gli eventi climatici estremi attraverso la realizzazione di interventi diffusi e tecnologicamente avanzati, avendo attenzione all’impatto non solo ambientale, ma anche di vulnerabilità sociale.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal6_icon} className={styles.goalImage} alt="Acqua pulita e servizi igienico-sanitari" />
                <img src={goal11_icon} className={styles.goalImage} alt="Città e comunità sostenibili" />
                <img src={goal15_icon} className={styles.goalImage} alt="Consumo e produzione responsabili" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel3} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_4 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria ecologica</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                La Città Metropolitana è un territorio in transizione che deve mettere in atto in modo coordinato programmi e azioni che vadano a creare aree urbane sostenibili, migliorando la qualità della vita e riducendo l’inquinamento atmosferico. Le azioni vanno a costruire un assetto urbano sempre più completo per quanto riguarda le sfide di transizione ecologica e sostenibilità. Si va ad intervenire su tutti gli aspetti di urbanizzazione che caratterizzano un territorio come quello metropolitano mitigando gli effetti di una precedente concezione di disintegrazione tra l’urbano e la natura.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal11_icon} className={styles.goalImage} alt="Città e comunità sostenibili" />
                <img src={goal13_icon} className={styles.goalImage} alt="Lotta contro il cambiamento climatico" />
                <img src={goal15_icon} className={styles.goalImage} alt="Vita sulla terra" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel4} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_5 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria digitale</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                La tecnologia digitale, con la dematerializzazione di molti processi produttivi e burocratici, è senza dubbio un abilitatore dello sviluppo per le imprese e per la società.
                Città Metropolitana di Milano, in linea con l'obiettivo dell'UE per quanto riguarda la digitalizzazione dei servizi pubblici, agisce per garantire che entro il 2030 i servizi pubblici online siano completamente accessibili a tutti, comprese le persone con disabilità. Si tratta di realizzare un ambiente digitale che fornisca strumenti facili da usare, efficienti e personalizzati con elevati standard di sicurezza e privacy.  Maggior è l’accesso ai servizi digitali per la comunità che abita il territorio, migliori sono le sinergie, le comunicazioni, la mobilità, lo scambio di conoscenze e l’accesso alle informazioni.
                Città Metropolitana di Milano vuole fornire strumenti digitali di semplice utilizzo in campo ambientale ai propri uffici e ai 133 Comuni. L’obiettivo è sviluppare dei servizi di supporto alle decisioni che rendano possibile un approccio data driven alla definizione di policy.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal4_icon} className={styles.goalImage} alt="Istruzione di qualità" />
                <img src={goal9_icon} className={styles.goalImage} alt="Imprese, innovazione e infrastrutture" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel5} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_6 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Traiettoria crescita economica</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                La promozione e il coordinamento dello sviluppo economico e sociale sono attribuite all’Ente come funzione fondamentale. Coerentemente a tale missione, Città Metropolitana di Milano si propone di attivare strategie e misure per sostenere, favorire e coordinare le attività economiche, attirare investimenti, elevare il livello di coesione sociale. Strategie di sviluppo sostenibile devono quindi porsi l’obiettivo di promuovere attrattività e competitività del sistema produttivo e, al contempo, essere inclusive nei confronti delle fasce di popolazione più deboli e dei soggetti svantaggiati, definendo azioni di sostegno che possano favorire concretamente una loro integrazione sociale e lavorativa.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal5_icon} className={styles.goalImage} alt="Parità di genere" />
                <img src={goal8_icon} className={styles.goalImage} alt="Lavoro dignitoso e crescita economica" />
                <img src={goal10_icon} className={styles.goalImage} alt="Ridurre le disuguaglianze" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel6} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}
          
          {activeButtons.button_7 && (
            <div className={styles.trendContent}>
              <div className={styles.trendIntro}>
                <h3 className={styles.trendTitle}>Valori</h3>
                <h4 className={styles.trendSubtitle}>Obiettivo</h4>
                <p className={styles.trendText}>
                Affinché le traiettorie, atte ad indicare le strategie di sviluppo sostenibile per tutto il territorio metropolitano, garantiscano una transizione giusta e responsabile devono essere sempre interpretate e adattate alla luce di questi goal. La crescita, lo sviluppo, vengono quindi accompagnati dalla consapevolezza che non si può raggiungere il compimento della visione del territorio senza lasciarsi guidare dai valori che questi goal rappresentano, in quanto strettamente interconnessi con le funzioni e le ambizioni dell’Ente.
                Oltre a fornire una guida alle azioni dell’Ente e del suo territorio, questi goal rappresentano anche degli impegni che verranno applicati trasversalmente in tutte le traiettorie di sviluppo.
                Infatti, si proseguirà ad accompagnare ogni innovazione con un’adeguata formazione del personale interno e ad essere promotore nonché sostenitore di percorsi di formazione continua e di sensibilizzazione su tutto il territorio metropolitano. La school of sustainability, strumento creato a supporto dell’Agenda metropolitana, sarà in questo senso strumento primario per accompagnare la formazione continua.
                Si rafforzerà e allargherà la rete di competenze e conoscenze grazie a un continuo dialogo e una intensa collaborazione con le realtà del territorio. Il NetLab, ulteriore strumento creato all’interno del percorso di creazione dell’Agenda, risulterà fondamentale nel creare sinergie di intenti, sarà infatti mezzo di scambio continuo tra gli attori del territorio e faciliterà progettualità condivise. Si manterrà l’impegno a rafforzare la trasparenza e la solidità dell’istituzione. Verrà creato sempre più spazio per un dialogo e per azioni concrete al fine di assottigliare i divari sociali ed economici presenti nell’area metropolitana attraverso le spinte fornite dai finanziamenti nazionali ed europei.
                </p>
              </div>
              <div className={styles.goalsImageWrapper}>
                <img src={goal1_icon} className={styles.goalImage} alt="Sconfiggere la povertà" />
                <img src={goal4_icon} className={styles.goalImage} alt="Istruzione di qualità" />
                <img src={goal5_icon} className={styles.goalImage} alt="Parità di genere" />
                <img src={goal16_icon} className={styles.goalImage} alt="Pace, giustizia e istituzioni solide" />
                <img src={goal17_icon} className={styles.goalImage} alt="Partnership per gli obiettivi" />
              </div>
              <h4 className={secondTrendSubtitle}>Indicatori</h4>
              <div className={styles.trendImagesWrapper}>
                <img src={tabel7} className={styles.trendImage} alt="Tabella degli indicatori" />
              </div>
              <p className={styles.additionalInfo}>
                Le frecce indicano, quando ricavabile dai dati già disponibili,
                l’andamento sul breve periodo per Città Metropolitana di Milano,
                secondo gli Obiettivi definiti per ciascun indicatore, ad
                eccezione delle voci indicate da * (dati regionali) o ** (dati nazionali).
              </p>
              <img
                src={arrowsExplanationsImg}
                className={styles.arrowsImage}
                alt="Spegazione del significato delle frecce"
              />
            </div>
          )}



        </div>
      </div>
      <Footer />
    </div>
  );
}
export default DevelopmentTrend;
