import { useRef } from "react";
import styles from "./Wheel.module.css";
import wheelImageNotMobile from "../data/wheel/SDG_Wheel_CM_Milano.svg";
import wheelImageMobile from "../data/wheel/SDG_Wheel_CM_Milano_Mobile.svg";
import logoImage from "../data/wheel/Logo_CM_Milano.svg";
import manuale from "../data/wheel/DataLab Milano - Manuale v1_1.pdf";

function Wheel(props) {
  const imageRef = useRef();

  function onClickHandler() {
    // by default we activate goal 1 (only by clicking on the wheel), don't come back to the wheel after

    imageRef.current.animate(
      [
        // keyframes

        { transform: "rotate(0)" },
        { transform: "rotate(360deg)" },
      ],
      // timing options
      {
        duration: 3000,
      }
    );
    // we wait 1.5 second and we toggle the visibility of wheel
    setTimeout(() => {
      props.setWheelActive(!props.wheelActive);
      props.setNavigationBarActive({
        dashboardActive: true,
        sdgPageActive: false,
        developmentPageActive: false,
        infoPageActive: false,
      });
      // click on the dashboard button in the navigation bar (when we do refreash and we're on different page we want to make sure that after clicking wheel we will always go to the dashboard page)
      const dashboarNavBtn = document.getElementById("dashboard");
      dashboarNavBtn.click();
    }, 1500);
  }

  return (
    <div className={styles.wrapper}>
      {!props.isMobileScreen && (
        <img
          ref={imageRef}
          className={styles.goalIcon}
          src={wheelImageNotMobile}
          alt="SDG Wheel"
          onClick={onClickHandler}
        />
      )}
      {props.isMobileScreen && (
        <img
          ref={imageRef}
          className={styles.goalIcon}
          src={wheelImageMobile}
          alt="SDG Wheel"
          onClick={onClickHandler}
        />
      )}
      <div className={styles.textContainer}>
        {!props.isMobileScreen && (
          <img className={styles.logoIcon} src={logoImage} alt="CM Milano" />
        )}
        <div className={styles.textWrapper}>
          <p className={styles.introText}>
            Datalab è un cruscotto di analisi e monitoraggio degli indicatori dei
            Sustainable Development Goals legati agli obiettivi e target
            dall’Agenda Metropolitana Sostenibile.
            <br />
          </p>
          <p className={styles.downloadTextWrapper}> <a className={styles.downloadText} href={manuale} download>
            Scarica <span >il manuale d’uso</span>
          </a>
          </p>
        </div>
        <button
          className={styles.exploreBtn}
          onClick={onClickHandler}
          tabIndex={0}
          type="submit"
        >
          Esplora
        </button>
      </div>
    </div>
  );
}
export default Wheel;
