import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import styles from "./Chart.module.css";
import ArrowsContainer from "./ArrowsContainer";
import ChartInfo from "./ChartInfo";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

function Chart(props) {
  const { chartData, chartUnit, arrowsData, chartReferenceValue } = props;
  const chartDataRounded = [];

  const values = [];
  function findAllValues(object) {
    Object.entries(object).map((arrayElement) => {
      if (
        arrayElement[0] !== "name" &&
        arrayElement[1] !== null &&
        (arrayElement[0] === regionalData_FrontEnd ||
          arrayElement[0] === countryData ||
          arrayElement[0] === provinceData ||
          arrayElement[0] === partCountryData_1 ||
          arrayElement[0] === partCountryData_2 ||
          arrayElement[0] === euData)
      ) {
        values.push(parseFloat(arrayElement[1]).toFixed(2));
      }
    });
  }

  function roundValues(object) {
    let updatedValue = {
      name: object.name,
    };
    Object.entries(object).forEach((arrayElement, index) => {
      if (index !== 0) {
        const yearName = arrayElement[0];
        const yearData =
          arrayElement[1] !== null
            ? parseFloat(arrayElement[1]).toFixed(2)
            : null;
        updatedValue = {
          ...updatedValue,
          [`${yearName}`]: yearData,
        };
      }
    });
    return updatedValue;
  }
  // store all the values in the array so as to use it later to find the axis range and round the values so as the rounded values will be displayed on the tooltip
  chartData.forEach((oneYearAllTypesData) => {
    findAllValues(oneYearAllTypesData);
    const roundedValue = roundValues(oneYearAllTypesData);
    chartDataRounded.push(roundedValue);
  });

  // set range of the chart ( if reference value is smaller than min it should be min, if reference value is bigger than max it should be max)
  let axisRange = [
    parseFloat((Math.min(...values) - 1).toFixed(1)),
    parseFloat((Math.max(...values) + 2).toFixed(1)),
  ];
  if (chartReferenceValue !== undefined && chartReferenceValue < axisRange[0]) {
    axisRange = [
      chartReferenceValue - 2,
      parseFloat((Math.max(...values) + 2).toFixed(1)),
    ];
  } else if (
    chartReferenceValue !== undefined &&
    chartReferenceValue > axisRange[1]
  ) {
    axisRange = [
      parseFloat((Math.min(...values) - 1).toFixed(1)),
      chartReferenceValue + 2,
    ];
  }
  // set textAnchor to middle to center the yAxis
  const yAxisTitle = document.querySelector(".recharts-text.recharts-label");
  if (yAxisTitle !== null) {
    yAxisTitle.classList.add(`${styles.yAxisTitle}`);
  }

  return (
    <>
    {
      props.mapDescription && (
        <div className={styles.container}>
          <ArrowsContainer
            chartData={chartData}
            arrowsData={arrowsData}
            activeIndicator={props.activeIndicator}
          />
          <ResponsiveContainer
            width="90%"
            height="100%"
            className={styles.responsive_container}
          >
            <LineChart
              className={styles.lineChart}
              data={chartDataRounded}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                className={styles.yAxisTitle}
                width={45}
                label={{
                  value: chartUnit,
                  angle: -90,
                  position: "insideLeft",
                  offset: -12,
                }}
                domain={axisRange}
              />
              <Tooltip />
              <ReferenceLine
                y={chartReferenceValue}
                strokeWidth={1}
                stroke="#cc008e"
                strokeDasharray="3 3"
              />
              <Legend />
              {chartData[0] !== undefined &&
                chartData[0][provinceData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={provinceData}
                    stroke="#6aa84f"
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][partCountryData_2] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={partCountryData_2}
                    stroke="#f1c232"
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][partCountryData_1] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={partCountryData_1}
                    stroke="#f1c232"
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][countryData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={countryData}
                    stroke="#4bc9ff"
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][euData] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={euData}
                    stroke="#0b4e8a"
                    strokeWidth={2}
                  />
                )}
              {chartData[0] !== undefined &&
                chartData[0][regionalData_FrontEnd] !== undefined && (
                  <Line
                    type="monotone"
                    dataKey={regionalData_FrontEnd}
                    stroke="#cc0000"
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                  />
                )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )
    }
    </>
  );
}

export default React.memo(Chart);
