import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import L from "leaflet";
import MapSlider from "./MapSlider";
import MapLegend from "./MapLegend";
import styles from "./Map.module.css";
import "leaflet/dist/leaflet.css";
import useHttp from "../../hooks/use-http";
import configData from "../../config.json";

const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano

function MapOZ(props) {
  const geojsonRef = useRef();
  const { isLoading, error, sendRequest: fetchData } = useHttp();
  const [currentYear, setCurrentYear] = useState(2020);
  const [currentYears, setCurrentYears] = useState([]);
  const [provincesData, setProvincesData] = useState([]);
  const [ourMap, setOurMap] = useState(null);
  const [legendRange, setLegendRange] = useState([]);
  const [oneYearValues, setOneYearValues] = useState([]);

  let indicatorValuesForOneYear = [];
  const indicatorValuesTotal = [];
  let years = [];
  function takeActiveYearData(feature) {
    let activeYear;
    let currentValue = currentYear;
    // return fake activeYear index just to visualize the polygons when there is no data
    if (feature.properties.indicatorvalues.length === 0) return 0;
    const lastIndicatorValue = Number(
      feature.properties.indicatorvalues[
        feature.properties.indicatorvalues.length - 1
      ].refyear
    );
    const firstIndicatorValue = Number(
      feature.properties.indicatorvalues[0].refyear
    );
    feature.properties.indicatorvalues.forEach((yearData, index) => {
      if (firstIndicatorValue > currentValue) {
        currentValue = firstIndicatorValue;
      }
      if (lastIndicatorValue < currentValue) {
        currentValue = lastIndicatorValue;
      }
      if (Number(yearData.refyear) === currentValue) {
        activeYear = { value: yearData.refyear, index };
      }
    });
    return activeYear.index;
  }

  function setCurrentLegendRange(value) {
    const activeYearDataId = takeActiveYearData(value);

    // push values for the active year to the array (we'll find after min and max)
    if (value.properties.indicatorvalues[activeYearDataId].refdata) {
      indicatorValuesForOneYear.push(
        parseFloat(
          value.properties.indicatorvalues[activeYearDataId].refdata.replaceAll(
            ",",
            "."
          )
        )
      );
    }
  }
  function setTotalLegendRange(value) {
    value.properties.indicatorvalues.forEach((oneYearValue) => {
      if (oneYearValue.refdata) {
        indicatorValuesTotal.push(parseFloat(oneYearValue.refdata));
      }
    });
  }
  const handleCittaMilanoData = (data) => {
    if (data.features[0].properties.indicatorvalues.length === 0) {
      // set the data of province without cleaning it and cutting ( just to desactivate polygons )
      setProvincesData([...data.features]);
      setLegendRange([]);
      return;
    }
    data.features.forEach((value) => {
      value.properties.indicatorvalues.sort((a, b) => {
        return Number(a.refyear) - Number(b.refyear);
      });
      // cut data starting from 2010
      const firstYearIndex = value.properties.indicatorvalues.findIndex(
        (indicatorValue) => Number(indicatorValue.refyear) >= 2010
      );
      value.properties.indicatorvalues.splice(0, firstYearIndex);

      // cut data which is empty
      props.cutEmptyData(value.properties.indicatorvalues);
      setCurrentLegendRange(value);
      setTotalLegendRange(value);

      value.properties.indicatorvalues.map((oneValue) => {
        oneValue.refdata = oneValue.refdata.replaceAll(",", ".");
      });

      // set current years ( to be used in slider) ( we take monza e della brianza because it hasn't been before 2010)

      years = [];
      value.properties.indicatorvalues.forEach((oneYearValue) => {
        if (!Number.isNaN(parseFloat(oneYearValue.refdata))) {
          years.push({ value: Number(oneYearValue.refyear) });
        }
      });
      setCurrentYears(years);
    });
    if (indicatorValuesForOneYear.length !== 0) {
      setOneYearValues(indicatorValuesForOneYear);
    }

    // sort data (to display tooltip correctly)
    data.features.map((feature) => {
      feature.geometry.coordinates.sort((a, b) => {
        return b[0].length - a[0].length;
      });
    });
    setProvincesData([...data.features]);
    const minValue = Math.min(...indicatorValuesTotal);
    const maxValue = Math.max(...indicatorValuesTotal);

    // set legedn range to a min and max value from all the years existing in the indicator
    setLegendRange({
      minValue,
      maxValue,
      indicatorid: data.features[0].properties.indicatorvalues[0].indicatorid,
    });
  };

  const provinces = `${configData.SERVER_URL}/indicatorsdataformap/zonaomogenea/${props.activeIndicator.data.id}/`;

  function takeData(url, handlDataFunction) {
    fetchData(
      {
        url,
      },
      handlDataFunction
    );
  }

  useEffect(() => {
    if (
      props.activeIndicator.nodeElement !== null &&
      props.activeIndicator.data.id ===
        Number(props.activeIndicator.nodeElement.attributes.id.value)
    ) {
      takeData(provinces, handleCittaMilanoData);
    }
  }, [props.activeIndicator]);

  const matchColor = (value, maxValue, interval) => {
    let color;
    const { colorScale } = props.currentTargets[0];
    const d = Number(parseFloat(value.replaceAll(",", ".")).toFixed(1));

    if (d === Number(maxValue.toFixed(1))) {
      color = colorScale[0];
    }
    if (
      d < Number(maxValue.toFixed(1)) &&
      d >= Number((maxValue - interval).toFixed(1))
    ) {
      color = colorScale[1];
    }
    if (
      d < Number((maxValue - interval).toFixed(1)) &&
      d >= Number((maxValue - interval * 2).toFixed(1))
    ) {
      color = colorScale[2];
    }
    if (
      d < Number((maxValue - interval * 2).toFixed(1)) &&
      d >= Number((maxValue - interval * 3).toFixed(1))
    ) {
      color = colorScale[3];
    }
    if (
      d < Number((maxValue - interval * 3).toFixed(1)) &&
      d >= Number((maxValue - interval * 4).toFixed(1))
    ) {
      color = colorScale[4];
    }
    if (
      d < Number((maxValue - interval * 4).toFixed(1)) &&
      d >= Number((maxValue - interval * 5).toFixed(1))
    ) {
      color = colorScale[5];
    }
    if (
      d < Number((maxValue - interval * 5).toFixed(1)) &&
      d >= Number((maxValue - interval * 6).toFixed(1))
    ) {
      color = colorScale[6];
    }
    if (
      d < Number((maxValue - interval * 6).toFixed(1)) &&
      d >= Number((maxValue - interval * 7).toFixed(1))
    ) {
      color = colorScale[7];
    }
    if (
      d < Number((maxValue - interval * 7).toFixed(1)) &&
      d >= Number((maxValue - interval * 8).toFixed(1))
    ) {
      color = colorScale[8];
    }
    if (d === Number((maxValue - interval * 8).toFixed(1))) {
      color = colorScale[9];
    }

    return color;
  };
  const getColor = (value) => {
    const { maxValue } = legendRange;
    const range = legendRange.maxValue - legendRange.minValue;
    const interval = range / 8;

    const color = matchColor(value, maxValue, interval);

    return color;
  };
  useEffect(() => {
    if (provincesData.length > 0) {
      indicatorValuesForOneYear = [];
      provincesData.forEach((province) => {
        setCurrentLegendRange(province);
      });
    }
  }, [currentYear]);

  // Show the message of loading and error state to user
  if (isLoading) {
    return (
      <div className={`${styles.message_container} ${styles.loading}`}>
        <p className={styles.loading_text}>Loading data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles.message_container} ${styles.error}`}>
        <p className={styles.error_text}>{error}</p>
      </div>
    );
  }

  const isDataActive =
    provincesData.length !== 0 &&
    provincesData[0].properties.indicatorvalues.length !== 0
      ? props.activeIndicator.data.id ===
          provincesData[0].properties.indicatorvalues[0].indicatorid &&
        props.activeIndicator.data.id === legendRange.indicatorid
      : false;

  const displayData =
    (provincesData.length !== 0 && isDataActive) ||
    (provincesData.length !== 0 &&
      legendRange.length === 0 &&
      provincesData[0].properties.indicatorvalues.length === 0);

  props.checkDataForMap(legendRange);

  return (
    <>
      <MapContainer
        className={styles["leaflet-container"]}
        center={[45.45, 9.3]}
        zoomSnap={0.25}
        zoom={9.5}
        minZoom={7}
        maxZoom={10}
        // scrollWheelZoom={false}
        // zoomControl={false}
        // boxZoom={false}
        whenCreated={(map) => {
          setOurMap(map);
          // map.dragging.disable();
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-b.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png"
        />

        {displayData && (
          <GeoJSON
            key={`${currentYear}year`}
            ref={geojsonRef}
            data={provincesData}
            style={{
              color: "black",
              weight: "1",
            }}
            onEachFeature={(feature, layer) => {
              if (
                isDataActive &&
                feature.properties.indicatorvalues.length !== 0 &&
                legendRange.length !== 0
              ) {
                const activeYearDataId = takeActiveYearData(feature);
                const name = feature.properties.nome;
                const value = parseFloat(
                  feature.properties.indicatorvalues[activeYearDataId].refdata
                ).toFixed(1);
                const roundedValue =
                  parseFloat(value) < 100
                    ? value
                    : parseFloat(value).toFixed(0);
                const tooltipText = `${name} : ${roundedValue} `;
                const tooltip = L.tooltip({
                  direction: "top",
                  className: `${styles.tooltip}`,
                });
                tooltip.setContent(tooltipText);
                layer.bindTooltip(tooltip).openTooltip();

                layer.setStyle({
                  fillColor: getColor(
                    feature.properties.indicatorvalues[activeYearDataId].refdata
                  ),
                  fillOpacity: 0.8,
                });
                layer.addEventListener("mouseover", () => {
                  layer.setStyle({ color: "black", weight: "3" });
                  setTimeout(() => {
                    layer.bringToFront();
                  }, 0);
                });
                layer.addEventListener("mouseout", () => {
                  layer.setStyle({ color: "black", weight: "1" });
                });
              } else {
                layer.setStyle({
                  fillColor: "#abd7df",

                  fillOpacity: 0.8,
                });
              }
            }}
          />
        )}
      </MapContainer>

      <MapLegend
        map={ourMap}
        legendRange={legendRange}
        matchColor={matchColor}
        chartUnit={props.chartUnit}
        currentYear={currentYear}
        activeIndicator={props.activeIndicator}
        currentTargets={props.currentTargets}
      />
      <MapSlider
        mapDescription={props.mapDescription}
        legendRange={legendRange}
        currentYears={currentYears}
        provincesData={provincesData}
        chartData={props.chartData}
        setCurrentYear={setCurrentYear}
        currentYear={currentYear}
      />
    </>
  );
}
export default React.memo(MapOZ);
