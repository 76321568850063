import styles from "./Footer.module.css";
import configData from "../config.json";
import coloureeLogo from "../data/Logo_Colouree.svg";

function Footer() {
  const email1 = `mailto: ${configData.FOOTER.EMAIL_1}`;
  const email2 = `mailto: ${configData.FOOTER.EMAIL_2}`;
  const logo1 = configData.FOOTER.ICON_1;
  return (
    <div className={styles.footer}>
      Città Metropolitana di Milano - Via Vivaio, 1 - 20122 Milano | &nbsp;
      <a href={email1} className={styles.mail_link}>
        PEC - Posta Elettronica Certificata
      </a>
      &nbsp;|&nbsp;
      <a href={email2} className={styles.mail_link}>
        PEO - Posta Elettronica Ordinaria
      </a>
      &nbsp;| P.IVA 08911820960 <br /> powered by
      <a
        href={logo1}
        className={styles.logo_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={coloureeLogo}
          className={styles.logo_icon}
          alt="Colouree logo"
        />
      </a>
    </div>
  );
}

export default Footer;
