import React from "react";
import styles from "./ContentContainerTitle.module.css";

function ContentContainerTitle(props) {
  const { activeTarget } = props;

  let content;
  if (props.activeIndicator != null && activeTarget != null) {
    content = (
      <>
        <h3 className={styles.targetName}>
          {activeTarget !== undefined &&
            `${activeTarget.num} ${activeTarget.description}`}
        </h3>
        <h3 className={styles.indicatorName}>
          {props.activeIndicator.nodeElement
            ? props.activeIndicator.nodeElement.textContent
            : props.activeIndicator.data.description}
        </h3>
      </>
    );
  }
  const backgroundColor = activeTarget !== null ? activeTarget.color : "white";

  return (
    <div
      className={styles.titleContainer}
      style={{ background: `${backgroundColor}` }}
      id="container_title"
    >
      {content !== undefined && content}
    </div>
  );
}
export default React.memo(ContentContainerTitle);
