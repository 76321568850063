// import of goals icons
import goal_1 from "../data/Goals/Goal_01.svg";
import goal_2 from "../data/Goals/Goal_02.svg";
import goal_3 from "../data/Goals/Goal_03.svg";
import goal_4 from "../data/Goals/Goal_04.svg";
import goal_5 from "../data/Goals/Goal_05.svg";
import goal_6 from "../data/Goals/Goal_06.svg";
import goal_7 from "../data/Goals/Goal_07.svg";
import goal_8 from "../data/Goals/Goal_08.svg";
import goal_9 from "../data/Goals/Goal_09.svg";
import goal_10 from "../data/Goals/Goal_10.svg";
import goal_11 from "../data/Goals/Goal_11.svg";
import goal_12 from "../data/Goals/Goal_12.svg";
import goal_13 from "../data/Goals/Goal_13.svg";
import goal_14 from "../data/Goals/Goal_14.svg";
import goal_15 from "../data/Goals/Goal_15.svg";
import goal_16 from "../data/Goals/Goal_16.svg";
import goal_17 from "../data/Goals/Goal_17.svg";
import pumsIcon from "../data/Goals/PUMS.svg";

// import of target icons
import target_01_02 from "../data/targets/Target_01-02.svg";
import target_02_03 from "../data/targets/Target_02-03.svg";
import target_02_04 from "../data/targets/Target_02-04.svg";
import target_03_02 from "../data/targets/Target_03-02.svg";
import target_03_04 from "../data/targets/Target_03-04.svg";
import target_03_06 from "../data/targets/Target_03-06.svg";
import target_04_01 from "../data/targets/Target_04-01.svg";
import target_04_02 from "../data/targets/Target_04-02.svg";
import target_04_03 from "../data/targets/Target_04-03.svg";
// import target_04_04 from "./data/targets/Target_04-04.svg";
import target_04_05 from "../data/targets/Target_04-05.svg";
import target_05_04 from "../data/targets/Target_05-04.svg";
import target_05_05 from "../data/targets/Target_05-05.svg";
// import target_06_03 from "./data/targets/Target_06-03.svg";
import target_06_04 from "../data/targets/Target_06-04.svg";
import target_06_06 from "../data/targets/Target_06-06.svg";
import target_07_02 from "../data/targets/Target_07-02.svg";
import target_07_03 from "../data/targets/Target_07-03.svg";
import target_08_01 from "../data/targets/Target_08-01.svg";
import target_08_03 from "../data/targets/Target_08-03.svg";
import target_08_05 from "../data/targets/Target_08-05.svg";
// import target_08_06 from "./data/targets/Target_08-06.svg";
import target_08_08 from "../data/targets/Target_08-08.svg";
import target_09_03 from "../data/targets/Target_09-03.svg";
import target_09_04 from "../data/targets/Target_09-04.svg";
import target_09_05 from "../data/targets/Target_09-05.svg";
import target_10_01 from "../data/targets/Target_10-01.svg";
import target_10_04 from "../data/targets/Target_10-04.svg";
import target_10_07 from "../data/targets/Target_10-07.svg";
import target_11_01 from "../data/targets/Target_11-01.svg";
import target_11_02 from "../data/targets/Target_11-02.svg";
// import target_11_03 from "./data/targets/Target_11-03.svg";
import target_11_04 from "../data/targets/Target_11-04.svg";
// import target_11_05 from "./data/targets/Target_11-05.svg";
import target_11_06 from "../data/targets/Target_11-06.svg";
import target_11_07 from "../data/targets/Target_11-07.svg";
import target_12_04 from "../data/targets/Target_12-04.svg";
// import target_12_05 from "./data/targets/Target_12-05.svg";
import target_13_01 from "../data/targets/Target_13-01.svg";
import target_13_02 from "../data/targets/Target_13-02.svg";
import target_14_01 from "../data/targets/Target_14-01.svg";
import target_14_03 from "../data/targets/Target_14-03.svg";
// import target_15_01 from "./data/targets/Target_15-01.svg";
import target_15_03 from "../data/targets/Target_15-03.svg";
import target_15_05 from "../data/targets/Target_15-05.svg";
import target_16_01 from "../data/targets/Target_16-01.svg";
import target_16_03 from "../data/targets/Target_16-03.svg";
import target_16_06 from "../data/targets/Target_16-06.svg";
import target_16_07 from "../data/targets/Target_16-07.svg";
import target_17_02 from "../data/targets/Target_17-02.svg";

const goals = [
  {
    goalIcon: goal_1,
    goalTitle: "Sconfiggere la povertà",
    targetIcons: [target_01_02],
    id: 1,
    color: "#DF2538",
    colorScale: [
      "#df2538",
      "#e23a4b",
      "#e5505f",
      "#e86673",
      "#eb7c87",
      "#ef929b",
      "#f2a7af",
      "#f5bdc3",
      "#f8d3d7",
      "#fbe9eb",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_2,
    goalTitle: "Sconfiggere la fame",
    targetIcons: [target_02_03, target_02_04],
    id: 2,
    color: "#E1AB31",
    colorScale: [
      "#e1ab31",
      "#e4b345",
      "#e7bb5a",
      "#eac46e",
      "#edcc83",
      "#f0d598",
      "#f3ddac",
      "#f6e5c1",
      "#f9eed5",
      "#fcf6ea",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_3,
    goalTitle: "Salute e benessere",
    targetIcons: [target_03_02, target_03_04, target_03_06],
    id: 3,
    color: "#4E9B3C",
    colorScale: [
      "#4e9b3c",
      "#5fa54f",
      "#71af62",
      "#83b976",
      "#94c38a",
      "#a6cd9d",
      "#b8d7b1",
      "#c9e1c4",
      "#dbebd8",
      "#edf5eb",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_4,
    goalTitle: "Istruzione di qualità",
    targetIcons: [target_04_01, target_04_02, target_04_03, target_04_05],
    id: 4,
    color: "#C5272F",
    colorScale: [
      "#c5272f",
      "#ca3c43",
      "#d05258",
      "#d6676d",
      "#dc7d82",
      "#e29397",
      "#e7a8ab",
      "#edbec0",
      "#f3d3d5",
      "#f9e9ea",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_5,
    goalTitle: "Parità di genere",
    targetIcons: [target_05_04, target_05_05],
    id: 5,
    color: "#E63A2B",
    colorScale: [
      "#e63a2b",
      "#e84d40",
      "#eb6155",
      "#ed756a",
      "#f0887f",
      "#f29c95",
      "#f5b0aa",
      "#f7c3bf",
      "#fad7d4",
      "#fcebe9",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_6,
    goalTitle: "Acqua pulita e servizi igienico-sanitari",
    targetIcons: [target_06_04, target_06_06],
    id: 6,
    color: "#3FBBE3",
    colorScale: [
      "#3fbbe3",
      "#52c1e5",
      "#65c8e8",
      "#78cfeb",
      "#8bd6ee",
      "#9fddf1",
      "#b2e3f3",
      "#c5eaf6",
      "#d8f1f9",
      "#ebf8fc",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_7,
    goalTitle: "Energia pulita e accessibile",
    targetIcons: [target_07_02, target_07_03],
    id: 7,
    color: "#F9C504",
    colorScale: [
      "#f9c504",
      "#f9ca1d",
      "#fad036",
      "#fad64f",
      "#fbdc68",
      "#fce281",
      "#fce79a",
      "#fdedb3",
      "#fdf3cc",
      "#fef9e5",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_8,
    goalTitle: "Lavoro dignitoso e crescita economica",
    targetIcons: [target_08_01, target_08_03, target_08_05, target_08_08],
    id: 8,
    color: "#A72843",
    colorScale: [
      "#a72843",
      "#af3d55",
      "#b85268",
      "#c1687b",
      "#ca7e8e",
      "#d393a1",
      "#dba9b3",
      "#e4bec6",
      "#edd4d9",
      "#f6e9ec",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_9,
    goalTitle: "Imprese, innovazione e infrastrutture",
    targetIcons: [target_09_03, target_09_04, target_09_05],
    id: 9,
    color: "#EB632C",
    colorScale: [
      "#eb632c",
      "#ed7241",
      "#ef8256",
      "#f1916b",
      "#f3a180",
      "#f5b195",
      "#f7c0aa",
      "#f9d0bf",
      "#fbdfd4",
      "#fdefe9",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_10,
    goalTitle: "Ridurre le disuguaglianze",
    targetIcons: [target_10_01, target_10_04, target_10_07],
    id: 10,
    color: "#DD1D7B",
    colorScale: [
      "#dd1d7b",
      "#e03388",
      "#e34a95",
      "#e760a2",
      "#ea77af",
      "#ee8ebd",
      "#f1a4ca",
      "#f4bbd7",
      "#f8d1e4",
      "#fbe8f1",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_11,
    goalTitle: "Città e comunità sostenibili",
    targetIcons: [
      target_11_01,
      target_11_02,
      target_11_04,
      target_11_06,
      target_11_07,
    ],
    id: 11,
    color: "#F59D21",
    colorScale: [
      "#f59d21",
      "#f6a637",
      "#f7b04d",
      "#f8ba63",
      "#f9c479",
      "#face90",
      "#fbd7a6",
      "#fce1bc",
      "#fdebd2",
      "#fef5e8",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_12,
    goalTitle: "Consumo e produzione responsabili",
    targetIcons: [target_12_04],
    id: 12,
    color: "#C58E24",
    colorScale: [
      "#c58e24",
      "#ca9939",
      "#d0a44f",
      "#d6af65",
      "#dcbb7b",
      "#e2c691",
      "#e7d1a7",
      "#edddbd",
      "#f3e8d3",
      "#f9f3e9",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_13,
    goalTitle: "Lotta contro il cambiamento climatico",
    targetIcons: [target_13_01, target_13_02],
    id: 13,
    color: "#477F43",
    colorScale: [
      "#477f43",
      "#598b55",
      "#6b9868",
      "#7ea57b",
      "#90b28e",
      "#a3bfa1",
      "#b5cbb3",
      "#c7d8c6",
      "#dae5d9",
      "#ecf2ec",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_14,
    goalTitle: "Vita sott'acqua",
    targetIcons: [target_14_01, target_14_03],
    id: 14,
    color: "#2597D3",
    colorScale: [
      "#2597D3",
      "#3aa1d7",
      "#50abdb",
      "#66b6e0",
      "#7cc0e4",
      "#92cbe9",
      "#a7d5ed",
      "#bddff1",
      "#d3eaf6",
      "#e9f4fa",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_15,
    goalTitle: "Vita sulla terra",
    targetIcons: [target_15_03, target_15_05],
    id: 15,
    color: "#60B238",
    colorScale: [
      "#60b238",
      "#6fb94b",
      "#7fc15f",
      "#8fc973",
      "#9fd087",
      "#afd89b",
      "#bfe0af",
      "#cfe7c3",
      "#dfefd7",
      "#eff7eb",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_16,
    goalTitle: "Pace, giustizia e istituzioni forti",
    targetIcons: [target_16_01, target_16_03, target_16_06, target_16_07],
    id: 16,
    color: "#17669D",
    colorScale: [
      "#17669d",
      "#2e75a6",
      "#4584b0",
      "#5c93ba",
      "#73a3c4",
      "#8bb2ce",
      "#a2c1d7",
      "#b9d1e1",
      "#d0e0eb",
      "#e7eff5",
      "#ffffff",
    ],
  },
  {
    goalIcon: goal_17,
    goalTitle: "Partnership per gli obiettivi",
    targetIcons: [target_17_02],
    id: 17,
    color: "#2B4B6C",
    colorScale: [
      "#2b4b6c",
      "#405d7a",
      "#556e89",
      "#6a8198",
      "#7f93a6",
      "#95a5b5",
      "#aab7c4",
      "#bfc9d2",
      "#d4dbe1",
      "#e9edf0",
      "#ffffff",
    ],
  },
];

export default goals;
