import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import configData from "../../config.json";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

const zo_AddaMartesana = configData.DATA.ZONE_OMOGENEE.ADDA_MARTESANA; // Adda Martesana
const zo_AltoMilanese = configData.DATA.ZONE_OMOGENEE.ALTO_MILANESE; // Alto Milanese
const zo_MagentinoAbbiatense =
  configData.DATA.ZONE_OMOGENEE.MAGENTINO_ABBIATENSE; // Magentino Abbiatense
const zo_Milano = configData.DATA.ZONE_OMOGENEE.MILANO; // Milano
const zo_NordMilano = configData.DATA.ZONE_OMOGENEE.NORD_MILANO; // Nord Milano
const zo_NordOvest = configData.DATA.ZONE_OMOGENEE.NORD_OVEST; // Nord Ovest
const zo_SudEst = configData.DATA.ZONE_OMOGENEE.SUD_EST; // Sud Est
const zo_SudOvest = configData.DATA.ZONE_OMOGENEE.SUD_OVEST; // Sud Ovest

const rowsToVisualize = [
  euData,
  countryData,
  partCountryData_1,
  partCountryData_2,
  provinceData,
  regionalData_FrontEnd,
  zo_AddaMartesana,
  zo_AltoMilanese,
  zo_MagentinoAbbiatense,
  zo_Milano,
  zo_NordMilano,
  zo_NordOvest,
  zo_SudEst,
  zo_SudOvest,
];
function CustomTable(props) {
  const { chartData, chartUnit } = props;
  const [tableHeight, setTableHeight] = useState(null);
  const [rowsData, setRowsData] = useState(null);
  const noSmallScreen = useMediaQuery("(min-width:500px)");

  function checkDisplayData(row) {
    let visualizeData = false;
    const rowFound = rowsToVisualize.find((rowName) => row.name === rowName);
    if (rowFound !== undefined) {
      visualizeData = true;
    } else {
      visualizeData = false;
    }
    return visualizeData;
  }

  // we will check if chartData > than 60 means that we will have comuni

  function checkComuniData() {
    if (
      chartData[0] !== undefined &&
      Object.keys(chartData[0]).slice(1).length > 60
    ) {
      Object.keys(chartData[0])
        .slice(1)
        .forEach((comuneName) => {
          rowsToVisualize.push(comuneName);
        });
    }
  }

  function prepareTableData() {
    checkComuniData();
    const data = [];
    Object.entries(chartData[0])
      .slice(1)
      .forEach((title) => {
        data.push({ name: title[0] });
      });
    data.map((row, index) => {
      chartData.forEach((column) => {
        rowsToVisualize.forEach((rowName) => {
          if (row.name === rowName) {
            data[index] = {
              ...data[index],
              [`${`year_${column.name}`}`]: `${column[rowName]}`,
            };
          }
        });
      });
    });
    return data;
  }

  // calculate dynamically the max height of the table ( consider the breakpoint 1260px )
  function calculateTableHeight() {
    const containerStyles = window.getComputedStyle(
      document.querySelector(".content_container")
    );
    const containerHeight = parseFloat(containerStyles.height.slice(0, -2));
    const containerTitleHeight = parseFloat(
      window
        .getComputedStyle(document.getElementById("container_title"))
        .height.slice(0, -2)
    );
    const containerNavigationStyles = window.getComputedStyle(
      document.getElementById("container_navigation")
    );
    const containerNavigationPaddingTop = parseFloat(
      containerNavigationStyles.paddingTop.slice(0, -2)
    );
    const containerNavigationPaddingBottom = parseFloat(
      containerNavigationStyles.paddingBottom.slice(0, -2)
    );
    const containerNavigationHeight =
      parseFloat(containerNavigationStyles.height.slice(0, -2)) +
      containerNavigationPaddingTop +
      containerNavigationPaddingBottom;
    const infoBox = document.getElementById("info_box");

    if (infoBox === null) return;
    const info_boxHeight = parseFloat(
      window.getComputedStyle(infoBox).height.slice(0, -2)
    );

    if (window.innerWidth > 1260) {
      setTableHeight(
        containerHeight -
          containerTitleHeight -
          containerNavigationHeight -
          info_boxHeight -
          32
      );
    } else {
      setTableHeight(
        containerHeight - containerNavigationHeight - info_boxHeight - 32
      );
    }
  }

  useEffect(() => {
    if (chartData.length !== 0) {
      const data = prepareTableData();
      setRowsData(data);
    }
  }, [props.activeIndicator, chartData]);

  useEffect(() => {
    calculateTableHeight();
  }, [props.activeIndicator, props.width, chartData]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(150, 150, 150)",
      color: theme.palette.common.white,
      fontSize: noSmallScreen ? 14 : 10,
      minWidth: noSmallScreen ? 70 : 50,
      maxWidth: noSmallScreen ? 70 : 50,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: noSmallScreen ? 14 : 10,
      minWidth: noSmallScreen ? 70 : 50,
      maxWidth: noSmallScreen ? 70 : 50,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableContainer = styled(TableContainer)({
    maxHeight: `${tableHeight}px`,
    maxWidth: "calc(100vw - 3rem)",
    "@media (min-width:801px) and (max-width:1260px)": {
      maxWidth: "calc(100vw - 270px - 3rem)",
    },
    "@media (min-width:1260px) and (max-width:1499px)": {
      maxWidth: "calc(100vw - 270px - 270px - 5rem)",
    },
    "@media (min-width:1500px)": {
      maxWidth: "calc(100vw - 318px - 334px - 5rem)",
    },
  });
  return (
    rowsData !== null && (
      <StyledTableContainer
        sx={{ minWidth: "100%", maxHeight: `${tableHeight}px` }}
        component={Paper}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{chartUnit}</StyledTableCell>
              {chartData.map((column) => {
                return (
                  <StyledTableCell align="right" key={column.name}>
                    {column.name}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map((row) => {
              const displayData = checkDisplayData(row);
              if (!displayData) return "";

              return (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  {Object.entries(row)
                    .slice(1)
                    .map((year) => {
                      const valueToShow = Number.isNaN(parseFloat(year[1]))
                        ? ""
                        : parseFloat(year[1]).toFixed(1);
                      return (
                        <StyledTableCell align="right" key={year}>
                          {valueToShow}
                        </StyledTableCell>
                      );
                    })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    )
  );
}

export default CustomTable;
