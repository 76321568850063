import React, { useState, useEffect } from "react";

import CotainerNavigation from "./ContainerNavigation";
import ContentBox from "./ContentBox";
import ContentContainerTitle from "./ContentContainerTitle";
import configData from "../../config.json";
import useHttp from "../../hooks/use-http";

const euData = configData.DATA.EU_DATA; // EU
const countryData = configData.DATA.COUNTRY_DATA; // Italia
const partCountryData_1 = configData.DATA.PART_COUNTRY_DATA_1; // Nord
const partCountryData_2 = configData.DATA.PART_COUNTRY_DATA_2; // Nord-ovest
const provinceData = configData.DATA.PROVINCE_DATA; // Lombardia
const regionalData_API = configData.DATA.REGIONE_DATA_API; // Milano
const regionalData_FrontEnd = configData.DATA.REGIONE_DATA_FRONTEND; // CM Milano

function ContentContainer(props) {
  const {
    currentTargets,
    activeIndicator,
    activeTarget,
    activeGoal,
    chartActive,
    setChartActive,
    mapActive,
    setMapActive,
    mapOZActive,
    setMapOZActive,
    tableActive,
    setTableActive,
    showChart,
    setShowChart,
    showMap,
    setShowMap,
    showMapOZ,
    setShowMapOZ,
  } = props;
  const { error, isLoading, sendRequest: fetchData } = useHttp();
  const [chartData, setChartData] = useState([]);
  const [chartUnit, setChartUnit] = useState(null);
  const [chartReferenceValue, setChartReferenceValue] = useState(null);
  const [arrowsData, setArrowsData] = useState([]);
  const [mapDescription, setMapDescription] = useState(null);
  const [mapDataExists, setMapDataExists] = useState(false);

  function checkDataForMap(legendRange) {
    if (legendRange !== undefined) {
      setMapDataExists(legendRange.length !== 0);
    }
  }

  function showNavigationButtons(fractions) {
    const dataZonaOmogeneaExists = fractions.find(
      (fraction) => fraction.def_json.dimensioni === "zonaomogenea"
    );
    const dataProvinceRegioneExists = fractions.find(
      (fraction) =>
        fraction.def_json.dimensioni === "nazione" ||
        fraction.def_json.dimensioni === "regione superiore" ||
        fraction.def_json.dimensioni === "regione" ||
        fraction.def_json.dimensioni === "province"
    );

    if (dataZonaOmogeneaExists && !dataProvinceRegioneExists) {
      setShowChart(false);
      setShowMap(false);
      setShowMapOZ(true);
    } else if (!dataZonaOmogeneaExists && dataProvinceRegioneExists) {
      setShowChart(true);
      setShowMap(true);
      setShowMapOZ(false);
    } else {
      setShowChart(true);
      setShowMap(true);
      setShowMapOZ(true);
    }
  }

  function handleData(data) {
    const updatedData = [];
    let arrowData = [];
    let referenceChartLine;
    const unit = data.note_measureunit;
    const dataSource = data.note_datasource;
    const dataMeasureMethod = data.note_measuremethod;
    const dataNoteTarget = data.note_target;
    showNavigationButtons(data.fractions);
    setMapDescription({
      source: dataSource,
      measureMethod: dataMeasureMethod,
      noteTarget: dataNoteTarget,
    });
    setChartUnit(unit);

    // we will take all the data with gaps without cutting anything  (each gap is set to null), on the chart and tables we willl visualize gaps
    // on the map thoug we have to cut the data so as to take only data which are continous

    data.fractions.forEach((fraction) => {
      let title = fraction.def_json.classe;
      if (
        title === regionalData_API &&
        fraction.def_json.dimensioni !== "zonaomogenea"
      ) {
        title = regionalData_FrontEnd;
        if (fraction.indicatorstatus[0].targetvalue !== null) {
          referenceChartLine = parseFloat(
            fraction.indicatorstatus[0].targetvalue.toFixed(2)
          );
        }
      } else if (
        title === regionalData_API &&
        fraction.def_json.dimensioni === "zonaomogenea"
      ) {
        title = "Milano";
      }

      // sort the data accroding to the year
      fraction.indicatorvalues.sort((a, b) => {
        return Number(a.refyear) - Number(b.refyear);
      });

      fraction.indicatorvalues.forEach((value, index) => {
        if (value.refdata !== null) {
          value.refdata.replaceAll(",", ".");
        }
        const yearData = value.refdata;
        const yearName = value.refyear;
        const updatedValue = {
          ...updatedData[index],
          name: yearName,
          [`${title}`]: yearData,
        };
        updatedData[index] = updatedValue;
      });
      // create and safe arrows data to the arrowsDataArray
      // if (fraction.def_json.dime nsioni !== "zonaomogenea") {
        const oneFractionArrow = fraction.indicatorstatus[0];
        if (oneFractionArrow !== undefined) {
          arrowData.push({ arrowName: title, status: oneFractionArrow });
        }
        // after we went through all frations we should reset the arrowsData array
        const allDataUpdated =
          fraction.id === data.fractions[data.fractions.length - 1].id;
        if (allDataUpdated) {
          // don't display data for the indicator  id:1,8 (the data is not correct)
          if (activeIndicator.data.id === 1 || activeIndicator.data.id === 8) {
            setArrowsData([]);
          } else {
            setArrowsData(arrowData);
          }
          arrowData = [];
        }
      // }
    });

    setChartReferenceValue(referenceChartLine);
    setChartData(updatedData);
  }

  function isDataAndNodeElementEqual(indicator) {
    let isDataEqualNode = false;
    const indicatorNotNull = indicator && indicator.nodeElement;
    if (
      indicatorNotNull &&
      indicator.data.id === Number(indicator.nodeElement.id)
    ) {
      isDataEqualNode = true;
    }
    return isDataEqualNode;
  }
  useEffect(() => {
    const dataEqualNodeElement = isDataAndNodeElementEqual(activeIndicator);
    if (activeIndicator && dataEqualNodeElement) {
      const indicatorId = activeIndicator.data.id;
      fetchData(
        {
          url: `${configData.SERVER_URL}/indicatorsdataforgraphics/${indicatorId}/`,
        },

        handleData
      );
    }
  }, [activeIndicator]);

  useEffect(() => {
   if (tableActive) {
      const exportTableIcon = document.body.querySelector("#exportTableIcon");
      exportTableIcon.style.marginLeft = "auto";
    }
  }, [tableActive]);

  return (
    <>
      <ContentContainerTitle
        currentTargets={currentTargets}
        activeIndicator={activeIndicator}
        activeTarget={activeTarget}
      />
      <CotainerNavigation
        mapState={{ mapActive, setMapActive }}
        chartState={{ chartActive, setChartActive }}
        tableState={{ tableActive, setTableActive }}
        mapOZState={{ mapOZActive, setMapOZActive }}
        activeGoal={activeGoal}
        activeIndicator={activeIndicator}
        mapDescription={mapDescription}
        mapDataExists={mapDataExists}
        showMap={showMap}
        showMapOZ={showMapOZ}
        showChart={showChart}
      />
      <ContentBox
        mapActive={mapActive}
        chartActive={chartActive}
        tableActive={tableActive}
        mapOZActive={mapOZActive}
        showMapOZ={showMapOZ}
        activeIndicator={activeIndicator}
        currentTargets={currentTargets}
        chartData={chartData}
        arrowsData={arrowsData}
        mapDescription={mapDescription}
        chartReferenceValue={chartReferenceValue}
        chartUnit={chartUnit}
        checkDataForMap={checkDataForMap}
        width={props.width}
      />
    </>
  );
}

export default ContentContainer;
